.App {
    text-align: center;
}

body {
    background-color: #202020;
    color: #f2f2f2;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}


h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.button-container {
    margin-top: 20px;
}

.load-more-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}


.pokemon img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 8px;
}

.pokemon h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.pokemon ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pokemon li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.pagination button {
    background-color: darkred;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
    margin-right: 10px;
    transition: background-color 0.2s ease-in-out;
}

.pagination button:hover {
    background-color: #FF5733;
}

.prev-btn {
    background-color: #ff0000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.prev-btn:hover {
    background-color: #ff3333;
}





